import { useGetAccount, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { logout } from "@multiversx/sdk-dapp/utils";
import { useEffect, useState } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import LoginModal from "./LoginModal";

const Header = () => {
  const { address } = useGetAccount();
  const navigate = useNavigate();
  const loginInfo = useGetLoginInfo();
  const [showLoginModal, setShowLoginModal] = useState(false);

  function openLoginModal(): void {
    setShowLoginModal(true);
  }

  useEffect(() => {
    if (address && showLoginModal) {
      setShowLoginModal(false);
      navigate("/dashboard");
    }
  }, [address, navigate, showLoginModal]);

  return (
    <Navbar bg="white" className="p-0 py-2" expand="lg">
      <LoginModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />
      <Container className="max-width navbar-container">
        <Navbar.Brand className="brand-container" href="/">
          <img className="logo" alt="Logo" src="/logo.png" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end ">
          <Nav className="align-items-center">
            {address && (
              <span className="me-2" title={address}>
                {address.substring(0, 4)}
                {address && "..."}
                {address.substring(address.length - 5)}
              </span>
            )}
            {address && (
              <Button
                className="p-0 me-2"
                variant="icon"
                title="Copy contract address to clipboard"
                onClick={() => navigator.clipboard.writeText(address)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ width: "1.5em", height: "1.5em" }}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#166534"
                  strokeWidth={1}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
              </Button>
            )}
            {address && (
              <Nav.Link>
                <Button
                  onClick={async () => {
                    await logout(loginInfo.loginMethod === "wallet" ? window.location.protocol + "//" + window.location.host : "/");
                  }}
                >
                  Logout
                </Button>
              </Nav.Link>
            )}
            {!address && (
              <Nav.Link>
                <Button onClick={() => openLoginModal()}>Connect wallet</Button>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
