import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import "./Home_old.css";
import TopImage from "../assets/TopImage.png";
import { useEffect, useState } from "react";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import {
  canClaim,
  canStake,
  canUnstake,
  canWithdraw,
  claimRewards,
  getCTPBalance,
  getEpoch,
  getStakingCollections,
  myPendingRewards,
  myStakedNfts,
  rewardsForEachDistribution,
  stake,
  totalStakedNfts,
  unstake,
  withdrawAll
} from "../services/stakingApi";
import { formatAmount } from "../services/utils";
import { stakingTokenName, stakingCollectionsNames } from "../config";

const Home = () => {
  const { address, account } = useGetAccountInfo();
  const [state, _setState] = useState({
    totalRewards: BigInt(0),
    totalNFTStacked: 0,
    yourRewards: BigInt(0),
    CTPBalance: BigInt(0),
    stakedNFTs: [],
    unstakedNFTs: [],
    selectedStakedNFT: "",
    ownedNFTs: [],
    selectedOwnedNFT: "",
    canStake: false,
    canUnstake: false,
    canClaim: false,
    canWithdraw: false,
    balance: "-1",
    currentEpoch: 999999999
  });

  const setState = (newState: any) => {
    _setState(current => ({ ...current, ...newState }));
  };

  useEffect(() => {
    if (account.balance === state.balance) {
      return;
    }

    if (address) {
      canUnstake(address).then((res: boolean) => setState({ canUnstake: res }));
      canStake(address).then((res: boolean) => setState({ canStake: res }));
      canClaim(address).then((res: boolean) => setState({ canClaim: res }));
      canWithdraw(address).then((res: boolean) => setState({ canWithdraw: res }));
      rewardsForEachDistribution().then((res: any) => setState({ totalRewards: res }));
      myPendingRewards(address).then((res: any) => setState({ yourRewards: res }));
      totalStakedNfts().then((res: any) => setState({ totalNFTStacked: res }));
      getStakingCollections(address).then(res => setState({ ownedNFTs: res }));
      getCTPBalance(address).then(res => setState({ CTPBalance: res }));
      myStakedNfts(address).then(res => setState({ stakedNFTs: res.staked, unstakedNFTs: res.unstaked }));
      getEpoch().then(res => setState({ currentEpoch: res }));
    }

    setState({ balance: account.balance });
  }, [address, account.balance, state.balance]);

  return (
    <>
      <div className="section-2">
        <Container className="max-width">
          <Row className="justify-content-center text-center">
            <Col xs="12" lg="4" className=" d-center">
              <img style={{ width: "50%" }} alt="Seed Captain cover" src={TopImage} />
            </Col>
            <Col xs="12" lg="8" className=" d-center">
              <p>
                Welcome to Seed Captain  NFT Staking Dashboard. <br/><br/>
                This is the place where you can stake your precious CTP collectible NFTs and generate passive income.<br/><br/>
                Rewards distribution every Friday at 16:00 utc. Lockup period 7 days.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="section-1">
        <Container className="max-width-sm">
          <Row>
            <h3>My Stake</h3>
          </Row>
          <Row style={{ backgroundColor: "white", padding: "2rem 1rem", borderRadius: 8 }}>
            <Col className="d-center justify-content-between mb-2 px-4" lg={6}>
              <span>Rewards per distribution:</span>
              <span>
                {" "}{formatAmount(state.totalRewards)} {stakingTokenName}
              </span>
            </Col>

            <Col className="d-center justify-content-between mb-2 px-4" lg={6}>
              <span>Total NFT staked in pool:</span> 
              <span>
                {" "}{formatAmount(state.totalNFTStacked, 0, 0)}
              </span>
            </Col>

            <Col className="d-center justify-content-between mb-2 px-4" lg={6}>
              <span>Your claimable rewards:</span>
              <span>
                {" "}{formatAmount(state.yourRewards)} {stakingTokenName}
              </span>
            </Col>

            <Col className="d-center justify-content-between mb-2 px-4" lg={6}>
              <span>Your balance:</span>
              <span>
                {" "}{formatAmount(state.CTPBalance)} {stakingTokenName}
              </span>
            </Col>
            <Col className="mt-1 ps-4">
              <Button variant="secondary" disabled={!state.canClaim || state.yourRewards === BigInt(0)} onClick={() => claimRewards()}>
                Claim Rewards
              </Button>
            </Col>
          </Row>
          {Boolean(state.stakedNFTs.length) && (
            <Row className="mt-5">
              <h4>Staked NFTs</h4>
            </Row>
          )}
          <Row>
            {state.stakedNFTs.map((nft: any) => (
              <Col lg={3} key={nft.identifier}>
                <div
                  onClick={() =>
                    setState({
                      selectedStakedNFT: nft.identifier
                    })
                  }
                  className={`nft-container ${state.selectedStakedNFT === nft.identifier ? "selected-nft" : ""}`}
                >
                  <img className="p-3 rounded" width={"100%"} src={nft.thumbnail} alt="NFT"></img>
                  {state.selectedStakedNFT === nft.identifier && (
                    <Button variant="secondary" className="mx-4 mb-3" onClick={() => unstake(nft)} disabled={!state.canUnstake}>
                      Unstake
                    </Button>
                  )}
                </div>
              </Col>
            ))}
          </Row>

          {Boolean(state.unstakedNFTs.length) && (
            <Row className="mt-5">
              <h4>Unstaked NFTs</h4>
            </Row>
          )}
          <Row>
            {state.unstakedNFTs.map((nft: any) => (
              <Col lg={3} className="d-center flex-column" key={nft.identifier}>
                <img className="p-3 rounded" width={"100%"} src={nft.thumbnail} alt="NFT"></img>
                <p>Remaining days: {Math.max(0, nft.withdrawInEpoch - state.currentEpoch)}</p>
              </Col>
            ))}
            {Boolean(state.unstakedNFTs.length) && (
              <Col xs={12} className="mt-2 ps-4">
                <Button
                  onClick={() => withdrawAll()}
                  variant="secondary"
                  disabled={
                    !state.canWithdraw
                  }
                >
                  Withdraw
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </Container>

      {state.ownedNFTs.length > 0 && (
        <div className="section-3 mb-5 pb-5">
          <Container fluid className="max-width mb-5 pb-5">
            <Row>
              <h3>My Collection</h3>
            </Row>
            <Row className="mb-5 pb-5">
              <Accordion defaultActiveKey="0">
                {state.ownedNFTs.map((collection: any[], index) => (
                  <Accordion.Item eventKey="0" key={index}>
                    <Accordion.Header>{stakingCollectionsNames.get(collection[0]?.collection)}</Accordion.Header>
                    <Accordion.Body>
                      <Row className="">
                        {collection.map(nft => (
                          <Col xs={6} md={4} lg={2} key={nft.identifier}>
                            <div
                              onClick={() =>
                                setState({
                                  selectedOwnedNFT: nft.identifier
                                })
                              }
                              className={`nft-container ${state.selectedOwnedNFT === nft.identifier ? "selected-nft" : ""}`}
                            >
                              <img className="p-3 rounded" width={"100%"} src={nft.url} alt="NFT"></img>
                              {state.selectedOwnedNFT === nft.identifier && (
                                <Button
                                  variant="secondary"
                                  className="mx-4 mb-3"
                                  onClick={() => {
                                    stake(nft, address);
                                  }}
                                  disabled={!state.canStake}
                                >
                                  Stake
                                </Button>
                              )}
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Home;
