import React from "react";

export function Ledger() {
  return (
    <svg viewBox="0 0 19 19" focusable="false" className="multiverseX-icon ledger">
      <path d="M7 0H16C17.6569 0 19 1.34315 19 3V12H7V0Z" fill="white"></path>
      <path d="M4 0H2C0.895431 0 0 0.895431 0 2V4H4V0Z" fill="white"></path>
      <path d="M0 15L2.78142e-07 17C4.31755e-07 18.1046 0.895431 19 2 19L4 19L4 15L0 15Z" fill="white"></path>
      <path d="M19 15L19 17C19 18.1046 18.1046 19 17 19L15 19L15 15L19 15Z" fill="white"></path>
      <path d="M4 8H0V12H4V8Z" fill="white"></path>
      <path d="M12 15H8V19H12V15Z" fill="white"></path>
    </svg>
  );
}
