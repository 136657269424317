import { ExtensionLoginButton, WebWalletLoginButton, LedgerLoginButton, WalletConnectLoginButton } from "@multiversx/sdk-dapp/UI";
import { Modal } from "react-bootstrap";
import "./LoginModal.css";
//import Ledger from "../assets/Ledger.png";
/*import MaiarApp from "../assets/MaiarApp.png";
import MaiarExtension from "../assets/MaiarExtension.png";
import WebWallet from "../assets/WebWallet.png";*/
import { X as MultiverseX } from "../assets/X";
import { Ledger } from "../assets/Ledger";
import { walletConnectV2ProjectId } from "config";

const LoginModal: React.FC<{
  show: any;
  handleClose: any;
}> = ({ show, handleClose }) => {
  const commonProps = {
    callbackRoute: "/",
    nativeAuth: true,
  };
  return (
    <Modal dialogClassName="login-modal" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton closeVariant="white" className="border-0 pb-0 align-items-baseline">
        <Modal.Title>
          <div className="modal-title">
            <h1>SEED CAPTAIN</h1>
            <p>Please select your login method</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="connect-buttons">
        <WalletConnectLoginButton
          {...(walletConnectV2ProjectId
            ? {
                isWalletConnectV2: true,
              }
            : {})}
        >
          <MultiverseX />
          <div className="buttonText">PORTAL</div>
          <img alt="" src="/img/angleRight2.5b13c79a.svg" width="9" height="14" decoding="async" data-nimg="1" loading="lazy"></img>
        </WalletConnectLoginButton>
        <ExtensionLoginButton >
          <MultiverseX />
          <div className="buttonText">DeFi Wallet</div>
          <img alt="" src="/img/angleRight2.5b13c79a.svg" width="9" height="14" decoding="async" data-nimg="1" loading="lazy"></img>
        </ExtensionLoginButton>
        <LedgerLoginButton {...commonProps}>
          <Ledger />
          <div className="buttonText">Ledger</div>
          <img alt="" src="/img/angleRight2.5b13c79a.svg" width="9" height="14" decoding="async" data-nimg="1" loading="lazy"></img>
        </LedgerLoginButton>
        <WebWalletLoginButton {...commonProps}>
          <MultiverseX />
          <div className="buttonText">Web Wallet</div>
          <img alt="" src="/img/angleRight2.5b13c79a.svg" width="9" height="14" decoding="async" data-nimg="1" loading="lazy"></img>
        </WebWalletLoginButton>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
