//import "@multiversx/sdk-dapp/dist/index.css";
import { TransactionsToastList, SignTransactionsModals, NotificationModal } from "@multiversx/sdk-dapp/UI";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Layout from "./components/Layout";
import { apiTimeout, network, walletConnectV2ProjectId } from "./config";
import "./App.css";
//import Staking from "./routes/Staking";

export const App = () => {

  return (
    <BrowserRouter>
      <DappProvider
        environment={network.id}
        customNetworkConfig={{
          name: "customConfig",
          apiTimeout,
          walletConnectV2ProjectId,
        }}
      >
        <Layout>
          <TransactionsToastList />
          <NotificationModal />
          <SignTransactionsModals />

          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Layout>
      </DappProvider>
    </BrowserRouter>
  );
};
