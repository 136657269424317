import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import "./variables.css";


const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(<App />);
