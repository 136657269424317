import { Row, Col, Container } from "react-bootstrap";

const FixedContainer = (props: any) => {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} className="p-0">
          {props.children}
        </Col>
      </Row>
    </Container>
  );
};

export default FixedContainer;
