import "./Layout.css";
import "./Card.css";
import "./Buttons.css";
import Header from "./Header";
import FixedContainer from "./FixedContainer";

const Layout = (props: any) => {
  return (
    <div className="app-container">
      <Header />
      <div>
        <FixedContainer>{props.children}</FixedContainer>
      </div>
    </div>
  );
};

export default Layout;
