import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { Address, AddressValue, ContractFunction, SmartContract } from "@multiversx/sdk-core/out";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { gasLimit, network, stakingCollections, stakingSC, stakingToken } from "../config";
import { bufferToHex, decimalToHex, hexToBigInt, hexToDecimal, parseHexResponse, stringToHex } from "./utils";

let provider: ProxyNetworkProvider;
let contract: SmartContract;

export async function rewardsForEachDistribution() {
  const res = await queryContractFunction("rewards_for_each_distribution");
  if (!res.returnData[0]) {
    return BigInt(0);
  }

  return hexToBigInt(bufferToHex(res.returnData[0]));
}

export async function myPendingRewards(address: string) {
  const res = await queryContractFunction("my_pending_rewards", address);
  console.log(res.returnData[0]);
  if (!res.returnData[0] || res.returnData[0] === "AAAAAA==") {
    return BigInt(0);
  }

  return hexToBigInt(bufferToHex(res.returnData[0]).substring(8));
}

export async function totalStakedNfts() {
  const res = await queryContractFunction("total_staked_nfts");
  if (!res.returnData[0]) {
    return BigInt(0);
  }

  return hexToDecimal(bufferToHex(res.returnData[0]));
}

export async function canStake(address: string) {
  const res = await queryContractFunction("can_user_stake", address);
  return res.returnCode?.toString() === "ok";
}

export async function canUnstake(address: string) {
  const res = await queryContractFunction("can_user_unstake", address);
  return res.returnCode?.toString() === "ok";
}

export async function canClaim(address: string) {
  const res = await queryContractFunction("can_user_claim", address);
  return res.returnCode?.toString() === "ok";
}

export async function canWithdraw(address: string) {
  const res = await queryContractFunction("can_user_withdraw", address);
  return res.returnCode?.toString() === "ok";
}

export async function myStakedNfts(address: string) {
  const res = await queryContractFunction("my_staked_nfts", address);
  const parsed: any[] = parseHexResponse(
    bufferToHex(res.returnData[0]),
    [
      {
        name: "staked_nft_id",
        type: "usize"
      },
      {
        name: "collection",
        type: "TokenIdentifier"
      },
      {
        name: "nonce",
        type: "u64"
      },
      {
        name: "withdraw_in_epoch",
        type: "u64"
      }
    ],
    true,
    true
  );

  const staked: any = [];
  const unstaked: any = [];
  for (let nft of parsed) {
    const parsedNFT = {
      identifier: `${nft.collection}-${nft.nonce}`,
      nonce: nft.nonce,
      collection: nft.collection,
      withdrawInEpoch: nft.withdraw_in_epoch,
      stakedNftId: nft.staked_nft_id,
      thumbnail: await fetch(network.apiAddress + `/nfts/${nft.collection}-${decimalToHex(nft.nonce)}`)
        .then(res => res.json())
        .then(res => res.media[0].thumbnailUrl)
    };

    parsedNFT.withdrawInEpoch === 0 ? staked.push(parsedNFT) : unstaked.push(parsedNFT);
  }
  return {
    staked,
    unstaked
  };
}

export async function stake(nft: any, address: string) {
  let transaction = {
    receiver: new Address(address).hex(),
    data: [
      "ESDTNFTTransfer",
      stringToHex(nft.collection),
      decimalToHex(nft.nonce),
      "01",
      new Address(stakingSC).hex(),
      stringToHex("stake_nft")
    ].join("@"),
    value: "0",
    gasLimit: gasLimit
  };

  sendTransactions({
    transactions: [transaction]
  });
}

export async function unstake(nft: any) {
  let transaction = {
    receiver: new Address(stakingSC).hex(),
    data: ["unstake_nft", decimalToHex(nft.stakedNftId)].join("@"),
    value: "0",
    gasLimit: gasLimit
  };

  sendTransactions({
    transactions: [transaction]
  });
}

export async function claimRewards() {
  let transaction = {
    receiver: new Address(stakingSC).hex(),
    data: "claim_rewards",
    value: "0",
    gasLimit: gasLimit
  };

  sendTransactions({
    transactions: [transaction]
  });
}

export async function withdrawAll() {
  let transaction = {
    receiver: new Address(stakingSC).hex(),
    data: "withdraw_all",
    value: "0",
    gasLimit: gasLimit
  };

  sendTransactions({
    transactions: [transaction]
  });
}

export async function getStakingCollections(address: string): Promise<any[]> {
  return await Promise.all(
    stakingCollections.map(async (collection: string) => {
      const url = `${network.apiAddress}/accounts/${address}/nfts?collections=${collection}&size=50`;
      const res = await fetch(url);
      return await res.json();
    })
  ).then(res => res.filter((collection: any[]) => collection.length > 0));
}

export async function getCTPBalance(address: string): Promise<BigInt> {
  const url = `${network.apiAddress}/accounts/${address}/tokens/${stakingToken}`;

  try {
    const res: any = await fetch(url);
    return BigInt((await res.json()).balance);
  } catch (error) {
    return BigInt(0);
  }
}

export async function getEpoch(): Promise<number> {
  const res = await fetch(`${network.apiAddress}/stats/`);
  return (await res.json()).epoch;
}

async function queryContractFunction(functionName: string, address?: string | null, args?: any[]) {
  
  if (!contract) {
    await initApi();
  }

  const options: any = {
    func: new ContractFunction(functionName)
  };
  
  if (address) {
    options.args = [new AddressValue(new Address(address))];
  }

  if (args) {
    if (options.args) {
      options.args.push(...args);
    } else {
      options.args = args;
    }
  }
  

  const query = contract.createQuery(options);
  const response = await provider.queryContract(query);

  return response;
}

async function initApi() {
  contract = new SmartContract({
    address: new Address(stakingSC)
  });
  provider = new ProxyNetworkProvider(network.apiAddress);
}
