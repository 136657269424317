export const stakingSC = "erd1qqqqqqqqqqqqqpgq5njnycnx4e3xx84asmvzqcgnaymfkyh0697qyjmfum";
export const gasLimit = "25000000";

export const stakingToken = "CTP-298075";
export const stakingTokenName = "$CTP";
export const stakingCollections = ["CTPWATER-e268f8", "CTPFIRE-37931b"];
export const stakingCollectionsNames = new Map([["CTPWATER-e268f8", "Water Rings"], ["CTPFIRE-37931b", "Fire Rings"]]);

export const network = {
  id: "mainnet",
  apiAddress: "https://api.multiversx.com",
  gatewayAddress: "https://gateway.multiversx.com",
  explorerAddress: "http://explorer.multiversx.com/"
};

export const walletConnectV2ProjectId = "b911c33c26805327582b5982194009e1";
export const apiTimeout = 6000;